define('formTransformations', ['singleColorSimpleForm'], function (singleColorSimpleForm) {

	var applyTransformations = function (transformations) {
		if (!transformations)
			transformations = [];
		transformations.forEach(function (transformation) {
			if (transformation == 'singleColorSimpleForm')
				singleColorSimpleForm.applyTransformation();
		});
	};

	return {
		applyTransformations: applyTransformations
	};
});
define('singleColorSimpleForm', ['jquery'], function ($) {
	
	var applyTransformation = function () {
		alert('hello world');
	};

	return {
		applyTransformation: applyTransformation
	};
});